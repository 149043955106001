body {
  font-family: 'Urbanist', sans-serif;
  padding: 0;
  background-color: #FFFFFF;
}
.container {
  max-width: 1220px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}